import {MposDate} from 'app/blocks/util/mpos-date';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IQuestionCategory, QuestionCategory} from 'app/blocks/model/check-list-category.model';
import {CustomerGroup, ICustomerGroup} from 'app/blocks/model/customer-group.model';
import {IDeliveryPoint, DeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {IHandheldProfile, HandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {IQuestion, Question} from 'app/blocks/model/question.model';

export interface ISurvey extends IBaseEntity {
    id?: number;
    name?: string;
    questions?: IQuestion[];
    categories?: IQuestionCategory[];
    startDate?: MposDate;
    endDate?: MposDate;
    timeOfSurvey?: string;
    surveyType?: string;
    deliveryPoints?: IDeliveryPoint[];
    customerGroups?: ICustomerGroup[];
    handheldProfiles?: IHandheldProfile[];
    hardStopSurvey?: boolean;
}

export class Survey extends BaseEntity implements ISurvey {
    public id?: number;
    public name?: string;
    public questions?: Question[];
    public categories?: QuestionCategory[];
    public startDate?: MposDate;
    public endDate?: MposDate;
    public timeOfSurvey?: string;
    public surveyType?: string;
    public deliveryPoints?: DeliveryPoint[];
    public customerGroups?: CustomerGroup[];
    public handheldProfiles?: HandheldProfile[];
    public hardStopSurvey?: boolean;

    constructor(survey?) {
        super();
        if (survey) {
            this.id = survey.id;
            this.name = survey.name;
            this.questions = survey.questions;
            this.categories = survey.categories;
            if (survey.startDate) {
                this.startDate = MposDate.newInstance(survey.startDate);
            }
            if (survey.endDate) {
                this.endDate = MposDate.newInstance(survey.endDate);
            }
            this.timeOfSurvey = survey.timeOfSurvey;
            this.surveyType = survey.surveyType;
            this.deliveryPoints = survey.deliveryPoints || [];
            this.handheldProfiles = survey.handheldProfiles || [];
            this.customerGroups = survey.customerGroups || [];
            this.hardStopSurvey = survey.hardStopSurvey;
        } else {
            this.deliveryPoints = [];
            this.handheldProfiles = [];
            this.customerGroups = [];
            this.startDate = MposDate.currentDate();
            this.endDate = MposDate.currentDate();
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Survey {
        return new Survey(this);
    }
}
